import React from 'react';
import Seo from 'components/seo';
import { Container, Box } from 'theme-ui';

import WebsiteData from '../../data/WebsiteData';
import TermsText from "../../data/TermsText";


const TermsPage = () => {
  return (
    <div>
      <Seo
        title={WebsiteData['SEO']['title']}
        description={WebsiteData['SEO']['description']}
      />
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "justify",
          }}
        >
          <TermsText />
        </Box>
      </Container>
    </div>
  );
}

export default TermsPage;
