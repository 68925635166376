import React from 'react';

const TermsText = () => {
    return (<div>
            <p>TERMS OF USE</p>
            <p>PLEASE READ THIS TERMS OF USE AGREEMENT CAREFULLY, AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR
                LEGAL RIGHTS AND REMEDIES.</p>
            <p>Last Revised: 2023-08-10</p>
            <ol>
                <li>OVERVIEW</li>
            </ol>
            <p>This Terms of Use Agreement ("Agreement") is entered into by and between BioLab Studios O&Uuml;,
                registered address Harju maakond, Tallinn, Kesklinna linnaosa, Ahtri tn 12, 15551, EE ("Company") and
                you, and is made effective as of the date of your use of this website http://www.biolabs.media ("Site")
                which will be considered the date of electronic acceptance.</p>
            <p>This Agreement sets forth the general terms and conditions of your use of the Site as well as the
                products and/or services purchased or accessed through the Site (the "Services"). Whether you are simply
                browsing or using the Site or purchasing Services, your use of the Site and your electronic acceptance
                of this Agreement signifies that you have read, understand, acknowledge and agree to be bound by this
                Agreement and by our Privacy policy. The terms "we", "us" or "our" shall refer to Company. The terms
                "you", "your", "User" or "customer" shall refer to any individual or entity who accepts this Agreement,
                uses our Site, has access to or uses the Services. Nothing in this Agreement shall be deemed to confer
                any third-party rights or benefits.</p>
            <p>Company may, at its sole and absolute discretion, change or modify this Agreement, and any policies or
                agreements which are incorporated herein, at any time, and such changes or modifications shall be
                effective immediately upon posting to the Site. Your use of the Site or of the Services after such
                changes or modifications have been made shall constitute your acceptance of this Agreement as last
                revised.</p>
            <p>IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AS LAST REVISED, DO NOT USE (OR CONTINUE TO USE) THE
                SITE OR THE SERVICES.</p>
            <ol start="2">
                <li>ELIGIBILITY</li>
            </ol>
            <p>This Site and the Services are available only to Users who can form legally binding contracts under
                applicable law. By using this Site or the Services, you represent and warrant that you are (i) at least
                eighteen (18) years of age, (ii) otherwise recognized as being able to form legally binding contracts
                under applicable law, and (iii) are not a person barred from purchasing or receiving the Services found
                under the laws of Estonia or other applicable jurisdiction.</p>
            <p>If you are entering into this Agreement on behalf of a company or any corporate entity, you represent and
                warrant that you have the legal authority to bind such corporate entity to the terms and conditions
                contained in this Agreement, in which case the terms "you", "your", "User" or "customer" shall refer to
                such corporate entity. If, after your electronic acceptance of this Agreement, Company finds that you do
                not have the legal authority to bind such corporate entity, you will be personally responsible for the
                obligations contained in this Agreement.</p>
            <ol start="3">
                <li>RULES OF USER CONDUCT</li>
            </ol>
            <p>By using the Site You acknowledge and agree that:</p>
            <p>Your use of this Site, including any content you submit, will comply with this Agreement and all
                applicable local, state, national and international laws, rules and regulations.</p>
            <p>You agree that you will not use this Site in a manner that:</p>
            <p>Is illegal, or promotes or encourages illegal activity;</p>
            <p>Promotes, encourages or engages in child pornography or the exploitation of children;</p>
            <p>Promotes, encourages or engages in terrorism, violence against people, animals, or property;</p>
            <p>Promotes, encourages or engages in any spam or other unsolicited bulk email, or computer or network
                hacking or cracking;</p>
            <p>Infringes on the intellectual property rights of another User or any other person or entity;</p>
            <p>Violates the privacy or publicity rights of another User or any other person or entity, or breaches any
                duty of confidentiality that you owe to another User or any other person or entity;</p>
            <p>Interferes with the operation of this Site;</p>
            <p>Contains or installs any viruses, worms, bugs, Trojan horses, Cryptocurrency Miners or other code, files
                or programs designed to, or capable of, using many resources, disrupting, damaging, or limiting the
                functionality of any software or hardware.</p>
            <p>You agree not to:</p>
            <p>copy or distribute in any medium any part of this Site, except where expressly authorized by Company,</p>
            <p>copy or duplicate this Terms of Use agreement,</p>
            <p>modify or alter any part of this Site or any of its related technologies,</p>
            <p>access Company's Content (as defined below) or User Content through any technology or means other than
                through the Site itself.</p>
            <ol start="4">
                <li>INTELLECTUAL PROPERTY</li>
            </ol>
            <p>In addition to the general rules above, the provisions in this Section apply specifically to your use of
                the Company Content posted to the Site. Company Content on this Site, including without limitation the
                text, software, scripts, source code, API, graphics, photos, sounds, music, videos and interactive
                features and the trademarks, service marks and logos contained therein ("Company Content"), are owned by
                or licensed to BioLab Studios O&Uuml; in perpetuity, and are subject to copyright, trademark, and/or
                patent protection.</p>
            <p>Company Content is provided to you "as is", "as available" and "with all faults" for your information and
                personal, non-commercial use only and may not be downloaded, copied, reproduced, distributed,
                transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any purposes whatsoever
                without the express prior written consent of Company. No right or license under any copyright,
                trademark, patent, or other proprietary right or license is granted by this Agreement.</p>
            <ol start="5">
                <li>LINKS TO THIRD-PARTY WEBSITES</li>
            </ol>
            <p>The Site may contain links to third-party websites that are not owned or controlled by Company. Company
                assumes no responsibility for the content, terms and conditions, privacy policies, or practices of any
                third-party websites. In addition, Company does not censor or edit the content of any third-party
                websites. By using this Site you expressly release Company from any and all liability arising from your
                use of any third-party website. Accordingly, Company encourages you to be aware when you leave this Site
                and to review the terms and conditions, privacy policies, and other governing documents of each other
                website that you may visit.</p>
            <ol start="6">
                <li>DISCLAIMER OF REPRESENTATIONS AND WARRANTIES</li>
            </ol>
            <p>YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE SHALL BE AT YOUR OWN RISK AND THAT THE
                SITE IS PROVIDED "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS". COMPANY, ITS OFFICERS, DIRECTORS,
                EMPLOYEES, AGENTS, DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
                TO, ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT. COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS MAKE NO REPRESENTATIONS OR
                WARRANTIES ABOUT (I) THE ACCURACY, COMPLETENESS, OR CONTENT OF THE SITE, (II) THE ACCURACY,
                COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO
                THIS SITE, AND/OR (III) THE SERVICES FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER
                ADVERTISING OR OTHERWISE) TO THIS SITE, AND COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR THE
                SAME.</p>
            <p>IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT NO ORAL OR WRITTEN INFORMATION OR ADVICE
                PROVIDED BY COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, AND THIRD-PARTY SERVICE PROVIDERS
                WILL (I) CONSTITUTE LEGAL OR FINANCIAL ADVICE OR (II) CREATE A WARRANTY OF ANY KIND WITH RESPECT TO THIS
                SITE OR THE SERVICES FOUND AT THIS SITE, AND USERS SHOULD NOT RELY ON ANY SUCH INFORMATION OR
                ADVICE.</p>
            <p>THE FOREGOING DISCLAIMER OF REPRESENTATIONS AND WARRANTIES SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY
                LAW, and shall survive any termination or expiration of this Agreement or your use of this Site or the
                Services found at this Site.</p>
            <ol start="7">
                <li>LIMITATION OF LIABILITY</li>
            </ol>
            <p>IN NO EVENT SHALL COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND ALL THIRD PARTY SERVICE
                PROVIDERS, BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING ANY DAMAGES THAT MAY RESULT FROM (I) THE
                ACCURACY, COMPLETENESS, OR CONTENT OF THIS SITE, (II) THE ACCURACY, COMPLETENESS, OR CONTENT OF ANY
                SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (III) THE SERVICES
                FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS
                SITE, (IV) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER, (V) THIRD-PARTY CONDUCT OF ANY
                NATURE WHATSOEVER, (VI) ANY INTERRUPTION OR CESSATION OF SERVICES TO OR FROM THIS SITE OR ANY SITES
                LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VII) ANY ELECTRONIC VIRUSES,
                WORMS, BUGS, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR FROM THIS SITE OR ANY SITES
                LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VIII) ANY USER CONTENT OR
                CONTENT THAT IS DEFAMATORY, HARASSING, ABUSIVE, HARMFUL TO MINORS OR ANY PROTECTED CLASS, PORNOGRAPHIC,
                "X-RATED", OBSCENE OR OTHERWISE OBJECTIONABLE, AND/OR (IX) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                RESULT OF YOUR USE OF THIS SITE OR THE SERVICES FOUND AT THIS SITE, WHETHER BASED ON WARRANTY, CONTRACT,
                TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT COMPANY IS ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES.</p>
            <p>IN ADDITION, You SPECIFICALLY ACKNOWLEDGE AND agree that any cause of action arising out of or related to
                this Site or the Services found at this Site must be commenced within one (1) year after the cause of
                action accrues, otherwise such cause of action shall be permanently barred.</p>
            <p>THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, AND shall
                survive any termination or expiration of this Agreement or your use of this Site or the Services found
                at this Site.</p>
            <ol start="8">
                <li>INDEMNITY</li>
            </ol>
            <p>You agree to protect, defend, indemnify and hold harmless Company and its officers, directors, employees,
                agents from and against any and all claims, demands, costs, expenses, losses, liabilities and damages of
                every kind and nature (including, without limitation, reasonable attorneys&rsquo; fees) imposed upon or
                incurred by Company directly or indirectly arising from (i) your use of and access to this Site; (ii)
                your violation of any provision of this Agreement or the policies or agreements which are incorporated
                herein; and/or (iii) your violation of any third-party right, including without limitation any
                intellectual property or other proprietary right. The indemnification obligations under this section
                shall survive any termination or expiration of this Agreement or your use of this Site or the Services
                found at this Site.</p>
            <ol start="9">
                <li>DATA TRANSFER</li>
            </ol>
            <p>If you are visiting this Site from a country other than the country in which our servers are located,
                your communications with us may result in the transfer of information across international boundaries.
                By visiting this Site and communicating electronically with us, you consent to such transfers. You also
                agree that it is your responsibillity to make sure that such transfers are legal and in accordance to
                the local and international laws.</p>
            <ol start="10">
                <li>AVAILABILITY OF WEBSITE</li>
            </ol>
            <p>Subject to the terms and conditions of this Agreement and our policies, we shall use commercially
                reasonable efforts to attempt to provide this Site on 24/7 basis. You acknowledge and agree that from
                time to time this Site may be inaccessible for any reason including, but not limited to, periodic
                maintenance, repairs or replacements that we undertake from time to time, or other causes beyond our
                control including, but not limited to, interruption or failure of telecommunication or digital
                transmission links or other failures.</p>
            <p>You acknowledge and agree that we have no control over the availability of this Site on a continuous or
                uninterrupted basis, and that we assume no liability to you or any other party with regard thereto.</p>
            <ol start="11">
                <li>DISCONTINUED SERVICES</li>
            </ol>
            <p>Company reserves the right to cease offering or providing any of the Services at any time, for any or no
                reason, and without prior notice. Although Company makes great effort to maximize the lifespan of all
                its Services, there are times when a Service we offer will be discontinued. If that is the case, that
                product or service will no longer be supported by Company. In such case, Company will either offer a
                comparable Service for you to migrate to or a refund. Company will not be liable to you or any third
                party for any modification, suspension, or discontinuance of any of the Services we may offer or
                facilitate access to.</p>
            <ol start="12">
                <li>NO THIRD-PARTY BENEFICIARIES</li>
            </ol>
            <p>Nothing in this Agreement shall be deemed to confer any third-party rights or benefits.</p>
            <ol start="13">
                <li>COMPLIANCE WITH LOCAL LAWS</li>
            </ol>
            <p>Company makes no representation or warranty that the content available on this Site are appropriate in
                every country or jurisdiction, and access to this Site from countries or jurisdictions where its content
                is illegal is prohibited. Users who choose to access this Site are responsible for compliance with all
                local laws, rules and regulations.</p>
            <ol start="14">
                <li>GOVERNING LAW</li>
            </ol>
            <p>This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or
                formation shall be governed by and construed in accordance with the laws of Estonia, to the exclusion of
                conflict of law rules.</p>
            <ol start="15">
                <li>DISPUTE RESOLUTION</li>
            </ol>
            <p>Any controversy or claim arising out of or relating to these Terms of Use will be settled by binding
                arbitration. Any such controversy or claim must be arbitrated on an individual basis, and must not be
                consolidated in any arbitration with any claim or controversy of any other party. The arbitration must
                be conducted in Estonia, and judgment on the arbitration award may be entered into any court having
                jurisdiction thereof.</p>
            <ol start="16">
                <li>TITLES AND HEADINGS</li>
            </ol>
            <p>The titles and headings of this Agreement are for convenience and ease of reference only and shall not be
                utilized in any way to construe or interpret the agreement of the parties as otherwise set forth
                herein.</p>
            <ol start="17">
                <li>SEVERABILITY</li>
            </ol>
            <p>Each covenant and agreement in this Agreement shall be construed for all purposes to be a separate and
                independent covenant or agreement. If a court of competent jurisdiction holds any provision (or portion
                of a provision) of this Agreement to be illegal, invalid, or otherwise unenforceable, the remaining
                provisions (or portions of provisions) of this Agreement shall not be affected thereby and shall be
                found to be valid and enforceable to the fullest extent permitted by law.</p>
            <ol start="18">
                <li>CONTACT INFORMATION</li>
            </ol>
            <p>If you have any questions about this Agreement, please contact us by email (via the contact form), or
                regular mail at the following address: BioLab Studios O&Uuml;, Harju maakond, Tallinn, Kesklinna
                linnaosa, Ahtri tn 12, 15551.</p>
        </div>
    )
}

export default TermsText;
